import { useLazyQuery } from '@apollo/client';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { map, trim } from 'lodash';
import React, { useEffect, useState } from 'react';
import { defaultDateFormat, MODULES } from '../../common/constants';
import CommonCard from '../../components/CommonCard';
import InfiniteScrollHandler from '../../components/InfiniteScrollHandler';
import Portal from '../../components/Portal';
import SearchComponent from '../../components/SearchComponent';
import AddEditCustomerModal from './AddEditCustomerModal';
import './customer.less';
import { CUSTOMER } from './graphql/Queries';

const Customer = () => {
  const initialCustomerFilter = {
    skip: 0,
    limit: 10,
  };

  const initialCustomerSort = {
    sortOn: 'createdAt',
    sortBy: 'DESC',
  };

  const [customerFilter, setCustomerFilter] = useState(initialCustomerFilter);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [search, setSearch] = useState('');
  const [scrollFlag, setScrollFlag] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [customerList, setCustomerList] = useState([]);
  const [getCustomer, { loading }] = useLazyQuery(CUSTOMER, {
    fetchPolicy: 'network-only',

    onCompleted: (res) => {
      const data = res?.customers?.customers;
      if (scrollFlag) {
        const datalist = [...customerList, ...data];
        setCustomerList(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setCustomerList(datalist);
      }
      setHasMore(!!data?.length);
    },
    onError() { },
  });

  useEffect(() => {
    getCustomer({
      variables: {
        filters: { ...customerFilter, search },
        sort: initialCustomerSort,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchUserDetails = () => {
    setCustomerFilter(initialCustomerFilter);
    getCustomer({
      variables: { ...initialCustomerFilter, ...initialCustomerSort },
    });
  };

  const onSearchChange = async (value) => {
    setSearch(trim(value));
    setCustomerFilter({ ...customerFilter, skip: 0, search: value });
    getCustomer({
      variables: {
        sort: initialCustomerSort,
        filters: {
          ...customerFilter,
          skip: 0,
          search: trim(value),
        },
      },
    });
  };

  const handleRefetch = () => {
    getCustomer({
      variables: {
        sort: initialCustomerSort,
        filters: {
          ...customerFilter,
          skip: customerList?.length,
          limit: 10,
        },
      },
    });
  };

  return (
    <div className="customer-module">
      <Portal portalId="header-wrapper">
        <div className="full-width d-flex align-center justify-between mb-12">
          <h2 className="m-0 font-600">{MODULES.CUSTOMER}</h2>
          <Button
            type="primary"
            className="mb-0 font-600"
            onClick={() => setIsModalOpen(true)}
          >
            ADD NEW
          </Button>
        </div>
      </Portal>
      <SearchComponent
        id="search-container-id"
        name="customer"
        getData={onSearchChange}
        className="mb-20 search-component"
      />
      <InfiniteScrollHandler
        scrollFlag={scrollFlag}
        loading={loading}
        refetchData={handleRefetch}
        setScrollFlag={setScrollFlag}
        hasMore={hasMore}
        dataLength={customerList?.length}
        skeletonRows={3}
      // wrapperClassName="category-list"
      >
        {map(customerList, (customer, index) => (
          <CommonCard key={customer?.id} className="customer-list mb-10">
            <div className="common-card d-flex">
              <div className="mr-5 title">{index + 1}.</div>
              <div>
                <div className="card-content text-secondary">
                  <div className="mb-5">
                    <span className="title mr-5">Name:</span>
                    <span className="text-break description">
                      {customer?.name}
                    </span>
                  </div>
                  <div className="mb-5">
                    <span className="title mr-5">Total Orders:</span>
                    <span className="text-break description">
                      {customer?.orderCount}
                    </span>
                  </div>
                  <div className="mb-5">
                    <span className="title mr-5">Email:</span>
                    <span className="text-break description">
                      {customer?.email || '-'}
                    </span>
                  </div>
                  <div className="mb-5">
                    <span className="title mr-5">Added On:</span>
                    <span className="text-break description">
                      {dayjs(customer?.createdAt).format(defaultDateFormat) ||
                        '-'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </CommonCard>
        ))}
      </InfiniteScrollHandler>
      {isModalOpen && (
        <AddEditCustomerModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          refetch={refetchUserDetails}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
        />
      )}
    </div>
  );
};
export default Customer;
