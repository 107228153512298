import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PdfIcon } from '../../assets/svg';
import { MODULES, ROUTES } from '../../common/constants';
import CommonCard from '../../components/CommonCard';
import InfiniteScrollHandler from '../../components/InfiniteScrollHandler';
import Portal from '../../components/Portal';
import '../customer/customer.less';
import ClearCartModal from './ClearCartModal';
import RemoveItemModal from './RemoveItemModal';
import './cart.less';
import { PLACE_ORDER } from './graphql/Mutations';
import { GET_CART_DETAILS } from './graphql/Queries';

const initialCartFilter = {
  filters: { skip: 0, limit: 10 },
  sort: { sortOn: 'createdAt', sortBy: 'DESC' },
};

const OrderConfirmation = () => {
  const navigate = useNavigate();
  const [cartData, setCartData] = useState([]);
  const [mainCartData, setMainCartData] = useState();
  const [scrollFlag, setScrollFlag] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [removeModal, setRemoveModal] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);
  const [clearCartModal, setClearCartModal] = useState(false);

  const [fetchCart, { loading }] = useLazyQuery(GET_CART_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.cart) {
        setMainCartData(res?.cart);
        const data = res?.cart?.items?.items;
        if (scrollFlag) {
          const datalist = [...cartData, ...data];
          setCartData(datalist);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setCartData(datalist);
        }
        setHasMore(!!data?.length);
      } else {
        setCartData([]);
      }
    },
    onError() { },
  });

  const [placeOrder] = useMutation(PLACE_ORDER, {
    fetchPolicy: 'network-only',
    onError() { },
    onCompleted(res) {
      navigate(`${ROUTES.ORDERS}/${res?.placeOrder?.order?.id}`, {
        replace: true,
        state: {
          justPlaced: true,
        },
      });
    },
  });

  useEffect(() => {
    fetchCart({ variables: initialCartFilter });
  }, []);

  const handleFetchMore = () => {
    fetchCart({
      variables: {
        ...initialCartFilter,
        filters: {
          skip: cartData?.length,
          limit: 10,
        },
      },
    });
  };

  const handleRefetch = () => {
    fetchCart({
      variables: {
        ...initialCartFilter,
        filters: {
          skip: 0,
          limit: 10,
        },
      },
    });
  };

  const handleSubmit = () => {
    placeOrder();
  };
  return (
    <div className="cart-module">
      {removeModal && (
        <RemoveItemModal
          isModalOpen={removeModal}
          setIsModalOpen={setRemoveModal}
          selectedRecord={itemToRemove}
          setSelectedRecord={setItemToRemove}
          refetch={handleRefetch}
        />
      )}
      {clearCartModal && (
        <ClearCartModal
          isModalOpen={clearCartModal}
          setIsModalOpen={setClearCartModal}
          refetch={handleRefetch}
        />
      )}
      <Portal portalId="header-wrapper">
        <h2 className="m-0 font-600 mb-12">{MODULES.ORDER_CONFIRMATION}</h2>
      </Portal>
      <div className="heading">Customer Details</div>
      <CommonCard
        key={mainCartData?.customer?.id}
        // onClick={() => setSelectedCustomer(customer?.id)}
        className="customer-list customer-data mb-10"
      >
        <div className="common-card d-flex space-between">
          {/* <div className="mr-5 title">{index + 1}.</div> */}
          <div>
            <div className="card-content text-secondary">
              <div className="mb-5">
                <span className="title mr-5">Name:</span>
                <span className="text-break description">
                  {mainCartData?.customer?.name}
                </span>
              </div>
              <div className="mb-5">
                <span className="title mr-5">Email:</span>
                <span className="text-break description">
                  {mainCartData?.customer?.email || '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </CommonCard>
      <div className="heading">Order Details</div>
      <InfiniteScrollHandler
        scrollFlag={scrollFlag}
        loading={loading}
        refetchData={handleFetchMore}
        setScrollFlag={setScrollFlag}
        hasMore={hasMore}
        dataLength={cartData?.length}
        skeletonRows={3}
        wrapperClassName="cart-list"
        customDescription="No items added"
      >
        {map(cartData, (item, index) => {
          const attachmentType = item?.product?.attachmentType;
          const attachment = item?.product?.attachments?.[0];
          // setValue(item?.quantity);
          return (
            <Card className="cart-card mb-8" key={index}>
              <div className="cart-content">
                <div className="d-flex align-start">
                  <div className="cart-attachment-div">
                    {attachmentType === 'IMAGE' ? (
                      <img
                        alt="example"
                        src={attachment?.url}
                        height={120}
                        width={120}
                      />
                    ) : (
                      <div className="pdf-detail-div">
                        <PdfIcon
                          alt="pdf"
                          key={attachment?.key}
                          height={100}
                          width={100}
                        />
                      </div>
                    )}
                  </div>
                  <div className="ml-12">
                    <h4 className="m-0">{item?.product?.code}</h4>
                    <p className="m-0 product-name">{item?.product?.name}</p>
                    <div className="mt-6 variations-div">
                      <p className="m-0">Quantity: {item?.quantity}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          );
        })}
      </InfiniteScrollHandler>
      <Portal portalId="footer-wrapper">
        <Button
          type="primary"
          htmlType="submit"
          className="full-width submit-btn"
          onClick={() => handleSubmit()}
        >
          Place Order
        </Button>
      </Portal>
    </div>
  );
};

export default OrderConfirmation;
