import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import { upperCase } from 'lodash';
import React from 'react';
import { MODULES, REGEX } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { CHANGE_PASSWORD } from './graphql/Mutations';

const { required } = formValidatorRules;

const ChangePasswordModal = ({ isModalOpen, setIsModalOpen }) => {
  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
    onError() {},
  });

  const onFinish = (values) => {
    const payload = {
      ...values,
    };
    delete payload.confirmPassword;
    changePassword({
      variables: {
        input: payload,
      },
    }).then((res) => {
      if (res?.data) {
        setIsModalOpen(false);
      }
    });
  };

  return (
    <Modal
      centered
      title={
        <h3 className="m-0 mb-10">{upperCase(MODULES.CHANGE_PASSWORD)}</h3>
      }
      footer={null}
      onCancel={() => setIsModalOpen(false)}
      open={isModalOpen}
      width={480}
      className="change-password-modal"
    >
      <Form
        name="change-password"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="category-forms change-password-form"
        layout="vertical"
      >
        <Form.Item
          name="oldPassword"
          label="Old Password"
          rules={[{ required, message: 'Please enter old password!' }]}
        >
          <Input.Password
            placeholder="Enter old password"
            className="password-input-btn"
          />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[
            { required, message: 'Please enter new password!' },
            {
              pattern: REGEX.NEW_PASSWORD,
              message:
                'Password should contain upper case, lower case, special character and number',
            },
          ]}
        >
          <Input.Password
            placeholder="Enter new password"
            className="password-input-btn"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={['newPassword']}
          rules={[
            { required, message: 'Please enter confirm password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value !== getFieldValue('newPassword')) {
                  return Promise?.reject(new Error('Passwords do not match'));
                }
                return Promise?.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            className="password-input-btn"
            placeholder="Enter confirm password"
          />
        </Form.Item>
        <Button
          type="primary"
          loading={loading}
          disabled={loading}
          className="mt-24 full-width submit-btn font-600"
          htmlType="submit"
        >
          Change Password
        </Button>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
