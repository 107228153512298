import { CloseCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Result, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { AppContext } from './AppContext';
import Error404 from './Error404';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import {
  BREAKPOINTS,
  ERROR_PAGE_SUBTITLE,
  ERROR_PAGE_TITLE,
  ROUTES,
} from './common/constants';
import LoaderComponent from './components/LoaderComponent';
import MaintenancePage from './components/MaintenancePage';
import history from './historyData';
import ForgetPassword from './modules/auth/ForgetPassword';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import RefreshToken from './modules/auth/RefreshToken';
import ResetPassword from './modules/auth/ResetPassword';
import SetPassword from './modules/auth/SetPassword';
import Signup from './modules/auth/Signup';
import { PROFILE } from './modules/auth/graphql/Queries';
import Cart from './modules/cart/Cart';
import CustomerList from './modules/cart/CustomerList';
import OrderConfirmation from './modules/cart/OrderConfirmation';
import Categories from './modules/categories/Categories';
import CategoryProducts from './modules/categories/components/products/CategoryProducts';
import ProductDetails from './modules/categories/components/products/ProductDetails';
import Customer from './modules/customer/Customer';
import CustomerDetails from './modules/customer/CustomerDetails';
import OrderDetail from './modules/orders/OrderDetail';
import Orders from './modules/orders/Orders';
import Profile from './modules/profile/Profile';

const { Paragraph } = Typography;

const MyFallbackComponent = ({ error, componentStack }) => (
  <Result
    status="error"
    title={ERROR_PAGE_TITLE}
    subTitle={ERROR_PAGE_SUBTITLE}
  >
    <div className="desc">
      <Paragraph>
        <Typography.Title level={4}> Error:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {error?.message?.toString()}
      </Paragraph>
      <Paragraph>
        <Typography.Title level={4}> Stacktrace:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {componentStack}
      </Paragraph>
    </div>
  </Result>
);

const RoutesCollection = () => {
  const AUTH_MODULES = [
    {
      path: ROUTES?.LOGIN,
      element: <PublicRoute />,
      // Nested routes use a children property
      children: [{ path: ROUTES?.LOGIN, element: <Login /> }],
    },
    {
      path: ROUTES?.FORGET_PASSWORD,
      element: <PublicRoute />,
      children: [
        { path: ROUTES?.FORGET_PASSWORD, element: <ForgetPassword /> },
      ],
    },
    {
      path: ROUTES?.SIGNUP,
      element: <PublicRoute />,
      children: [{ path: ROUTES?.SIGNUP, element: <Signup /> }],
    },
    {
      path: ROUTES?.RESET,
      element: <PublicRoute />,
      children: [{ path: ROUTES?.RESET, element: <ResetPassword /> }],
    },
    {
      path: ROUTES?.SET_PASSWORD,
      element: <PublicRoute />,
      children: [{ path: ROUTES?.SET_PASSWORD, element: <SetPassword /> }],
    },
    {
      path: ROUTES?.AUTHENTICATION,
      element: <PrivateRoute />,
      children: [{ path: ROUTES?.AUTHENTICATION, element: <RefreshToken /> }],
    },
    {
      path: ROUTES?.LOGOUT,
      element: <PrivateRoute />,
      children: [{ path: ROUTES?.LOGOUT, element: <Logout /> }],
    },
  ];

  const CATEGORIES_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            { path: '', element: <Navigate to={ROUTES.CATEGORIES} /> },
            {
              path: ROUTES?.CATEGORIES,
              children: [
                { path: ROUTES?.CATEGORIES, element: <Categories /> },
                {
                  path: ':id',
                  children: [
                    { path: '', element: <Navigate to={ROUTES.CATEGORIES} /> },
                    {
                      path: 'products',
                      element: <CategoryProducts />,
                    },
                  ],
                },
                {
                  path: ':id/products/:productId',
                  element: <ProductDetails />,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const CUSTOMER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.CUSTOMER,
              children: [
                { path: ROUTES?.CUSTOMER, element: <Customer /> },
                { path: ':id', element: <CustomerDetails /> },
              ],
            },
          ],
        },
      ],
    },
  ];

  const USER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.PROFILE,
              element: <Profile />,
            },
          ],
        },
      ],
    },
  ];

  const CART_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.CART,
              children: [{ path: ROUTES?.CART, element: <Cart /> }],
            },
            {
              path: ROUTES?.SELECT_CUSTOMER,
              children: [
                { path: ROUTES?.SELECT_CUSTOMER, element: <CustomerList /> },
              ],
            },
            {
              path: ROUTES?.ORDER_CONFIRMATION,
              children: [
                {
                  path: ROUTES?.ORDER_CONFIRMATION,
                  element: <OrderConfirmation />,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const ORDERS_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.ORDERS,
              children: [{ path: ROUTES?.ORDERS, element: <Orders /> }],
            },
            {
              path: ROUTES?.ORDER_DETAIL,
              children: [
                { path: ROUTES?.ORDER_DETAIL, element: <OrderDetail /> },
              ],
            },
          ],
        },
      ],
    },
  ];

  const OTHER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: '*',
              element: <Error404 />,
            },
            {
              path: '404',
              element: <Error404 />,
            },
          ],
        },
      ],
    },
  ];

  const element = useRoutes([
    ...AUTH_MODULES,
    ...CATEGORIES_MODULES,
    ...USER_MODULES,
    ...OTHER_MODULES,
    ...CUSTOMER_MODULES,
    ...CART_MODULES,
    ...ORDERS_MODULES,
  ]);
  return element;
};

const RoutesWrapper = () => {
  const { initializeAuth, getToken } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const path = history?.location?.pathname;
  const idToken = getToken();
  const [isDesktop, setDesktop] = useState(
    // eslint-disable-next-line no-undef
    window.innerWidth > BREAKPOINTS.tablet,
  );
  useEffect(() => {
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      if (window.innerWidth > BREAKPOINTS.tablet) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  });

  const [getCurrentUser] = useLazyQuery(PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      initializeAuth(idToken, res?.profile);
      setLoading(false);
    },
    onError: () => {
      history?.push(ROUTES?.LOGOUT);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (path === ROUTES?.LOGOUT || idToken) {
      getCurrentUser();
    } else {
      setLoading(false);
    }

    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  if (loading) return <LoaderComponent />;
  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      {isDesktop ? (
        <div className="desktop-no-preview">
          <Result
            status="403"
            title="Oops!"
            subTitle="You can not access this page in desktop, please use mobile device."
          />
        </div>
      ) : (
        <Router>
          {maintenance === 'true' ? <MaintenancePage /> : <RoutesCollection />}
        </Router>
      )}
    </Sentry.ErrorBoundary>
  );
};
export default RoutesWrapper;
