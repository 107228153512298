import { useMutation } from '@apollo/client';
import { Button, Form, Input, Spin } from 'antd';
import React from 'react';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { RESET_PASSWORD } from './graphql/Mutations';

const { required } = formValidatorRules;

const ResetPassword = () => {
  const {
    navigate,
    location: { search },
  } = useRouter();
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);
  const params = new URLSearchParams(search);
  const token = params?.get('token');
  const uid = params?.get('uid');

  const onFinish = async ({ password }) => {
    try {
      const response = await resetPassword({
        variables: {
          input: {
            resetToken: token,
            newPassword: password?.trim(),
            uid,
          },
        },
      });
      if (response) {
        navigate(ROUTES?.LOGIN, { replace: true });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <div className="auth-bg">
      <div className="login-wrap">
        <Spin spinning={loading} className="full-width">
          <h1 className="m-0">RESET PASSWORD</h1>
          <p>Enter a new password for your account</p>
          <Form
            name="reset-password"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className="category-forms"
            layout="vertical"
          >
            <Form.Item
              name="password"
              label="New Password"
              rules={[{ required, message: 'Please enter password!' }]}
            >
              <Input.Password
                placeholder="Enter password"
                className="password-input"
              />
            </Form.Item>
            <Form.Item
              name="retype-password"
              label="Confirm Password"
              rules={[
                { required, message: 'Please enter confirm password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value !== getFieldValue('password')) {
                      return Promise?.reject(
                        new Error('Passwords do not match'),
                      );
                    }
                    return Promise?.resolve();
                  },
                }),
              ]}
            >
              <Input.Password
                className="password-input"
                placeholder="Enter confirm password"
              />
            </Form.Item>
            <Form.Item className="full-width mb-8">
              <Button
                type="primary"
                className="full-width mb-0 submit-btn font-600"
                htmlType="submit"
              >
                Reset Password
              </Button>
            </Form.Item>
            <Form.Item className="text-center mb-0">
              <Button
                type="link"
                className="full-width mb-0 submit-btn font-600"
                onClick={() => {
                  navigate(ROUTES?.LOGIN);
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default ResetPassword;
