import { gql } from '@apollo/client';

export const REMOVE_ITEM_FROM_CART = gql`
  mutation RemoveItemFromCart($where: UniqueWhereInput!) {
    removeItemFromCart(where: $where) {
      message
    }
  }
`;

export const CLEAR_CART = gql`
  mutation ClearCart {
    clearCart {
      message
    }
  }
`;

export const UPDATE_CART_ITEM = gql`
  mutation UpdateCartItem($input: [UpdateCartItemInput!]!) {
    updateCartItem(input: $input) {
      message
    }
  }
`;

export const UPSERT_CART_CUSTOMER = gql`
  mutation UpsertCartCustomer($input: UpsertCartCustomerInput!) {
    upsertCartCustomer(input: $input) {
      message
    }
  }
`;

export const PLACE_ORDER = gql`
  mutation PlaceOrder {
    placeOrder {
      message
      order {
        id
      }
    }
  }
`;
