import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button } from 'antd';
import { map, trim } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RadioCircle, RadioFilledCircle } from '../../assets/svg';
import { MODULES, ROUTES } from '../../common/constants';
import CommonCard from '../../components/CommonCard';
import InfiniteScrollHandler from '../../components/InfiniteScrollHandler';
import Portal from '../../components/Portal';
import SearchComponent from '../../components/SearchComponent';
import AddEditCustomerModal from '../customer/AddEditCustomerModal';
import '../customer/customer.less';
import { CUSTOMER } from '../customer/graphql/Queries';
import { UPSERT_CART_CUSTOMER } from './graphql/Mutations';
import { GET_CART_DETAILS } from './graphql/Queries';

const CustomerList = () => {
  const navigate = useNavigate();

  const initialCustomerFilter = {
    skip: 0,
    limit: 10,
  };

  const initialCustomerSort = {
    sortOn: 'createdAt',
    sortBy: 'DESC',
  };

  const [customerFilter, setCustomerFilter] = useState(initialCustomerFilter);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [search, setSearch] = useState('');
  const [scrollFlag, setScrollFlag] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [getCustomer, { loading }] = useLazyQuery(CUSTOMER, {
    fetchPolicy: 'network-only',

    onCompleted: (res) => {
      const data = res?.customers?.customers;
      if (scrollFlag) {
        const datalist = [...customerList, ...data];
        setCustomerList(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setCustomerList(datalist);
      }
      setHasMore(!!data?.length);
    },
    onError() { },
  });

  const [upsertCartCustomer] = useMutation(UPSERT_CART_CUSTOMER, {
    fetchPolicy: 'network-only',
    onError() { },
    onCompleted() {
      navigate(ROUTES.ORDER_CONFIRMATION);
    },
  });

  const { loading: cartLoading } = useQuery(GET_CART_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.cart) {
        setSelectedCustomer(res?.cart?.customer?.id);
      }
    },
    onError() { },
  });

  useEffect(() => {
    getCustomer({
      variables: {
        filters: { ...customerFilter, search },
        sort: initialCustomerSort,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchUserDetails = () => {
    setCustomerFilter(initialCustomerFilter);
    getCustomer({
      variables: { ...initialCustomerFilter, ...initialCustomerSort },
    });
  };

  const onSearchChange = async (value) => {
    setSearch(trim(value));
    setCustomerFilter({ ...customerFilter, skip: 0, search: value });
    getCustomer({
      variables: {
        sort: initialCustomerSort,
        filters: {
          ...customerFilter,
          skip: 0,
          search: trim(value),
        },
      },
    });
  };

  const handleRefetch = () => {
    getCustomer({
      variables: {
        sort: initialCustomerSort,
        filters: {
          ...customerFilter,
          skip: customerList?.length,
          limit: 10,
        },
      },
    });
  };
  const handleSubmit = () => {
    upsertCartCustomer({
      variables: {
        input: {
          customerId: selectedCustomer,
        },
      },
    });
  };
  return (
    <div className="customer-module">
      <Portal portalId="header-wrapper">
        <div className="full-width d-flex align-center justify-between mb-12">
          <h2 className="m-0 font-600">{MODULES.SELECT_CUSTOMER}</h2>
          <Button
            type="primary"
            className="mb-0 font-600"
            onClick={() => setIsModalOpen(true)}
          >
            ADD NEW
          </Button>
        </div>
      </Portal>
      <SearchComponent
        id="search-container-id"
        name="customer"
        getData={onSearchChange}
        className="mb-20 search-component"
      />
      <InfiniteScrollHandler
        scrollFlag={scrollFlag}
        loading={loading || cartLoading}
        refetchData={handleRefetch}
        setScrollFlag={setScrollFlag}
        hasMore={hasMore}
        dataLength={customerList?.length}
        skeletonRows={3}
      >
        {map(customerList, (customer, index) => (
          <CommonCard
            key={customer?.id}
            onClick={() => setSelectedCustomer(customer?.id)}
            className="customer-list mb-10"
          >
            <div className="common-card d-flex space-between">
              <div className="d-flex full-width">
                <div className="mr-5 title">{index + 1}.</div>
                <div>
                  <div className="card-content text-secondary">
                    <div className="mb-5">
                      <span className="title mr-5">Name:</span>
                      <span className="text-break description">
                        {customer?.name}
                      </span>
                    </div>
                    <div className="mb-5">
                      <span className="title mr-5">Email:</span>
                      <span className="text-break description">
                        {customer?.email || '-'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {selectedCustomer === customer?.id ? (
                  <RadioFilledCircle />
                ) : (
                  <RadioCircle />
                )}
              </div>
            </div>
          </CommonCard>
        ))}
      </InfiniteScrollHandler>
      <Portal portalId="footer-wrapper">
        <Button
          type="primary"
          htmlType="submit"
          className="full-width submit-btn"
          disabled={!selectedCustomer}
          onClick={() => handleSubmit()}
        >
          Save & Continue
        </Button>
      </Portal>
      {isModalOpen && (
        <AddEditCustomerModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          refetch={refetchUserDetails}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
        />
      )}
    </div>
  );
};
export default CustomerList;
