import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Form, Input, Row, Upload } from 'antd';
import React, { useState } from 'react';
import { MODULES } from '../../common/constants';
import {
  beforeUpload,
  formValidatorRules,
  getBase64File,
} from '../../common/utils';
import Portal from '../../components/Portal';
import ChangePasswordModal from '../auth/ChangePasswordModal';
import { UPDATE_PROFILE } from '../auth/graphql/Mutations';
import { PROFILE } from '../auth/graphql/Queries';

const { required, name, email } = formValidatorRules;

function Profile() {
  const [btnLoading, setBtnLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const [form] = Form?.useForm();
  const { data: userData } = useQuery(PROFILE);
  const [openModal, setOpenModal] = useState(false);
  const [fieldsChange, setFieldsChange] = useState(false);

  const [updateCurrentUser] = useMutation(UPDATE_PROFILE, {
    onCompleted: () => {
      // dispatch({
      //   type: 'SET_CURRENT_USER',
      //   data: res?.updateCurrentUser?.data,
      // });
      // initializeAuth(idToken, res?.updateCurrentUser?.data);
    },
    onError: () => {
      form?.setFieldsValue(userData);
      setBtnLoading(false);
    },
  });

  const handleChange = (info) => {
    if (info?.file?.status === 'uploading') {
      setBtnLoading(true);
      return;
    }
    if (info?.file?.status === 'done') {
      // Get this url from response in real world.
      getBase64File(info?.file?.originFileObj, (url) => {
        setBtnLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <div>
      {btnLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="mt-8">Upload</div>
    </div>
  );

  const onFinish = async (values) => {
    setBtnLoading(true);
    const userObj = {
      firstName: values?.firstName?.trim(),
      lastName: values?.lastName?.trim(),
      // It is set as disabled as decided
      // email: values?.email?.trim()
      // commented as of now.
      // profileImage: values?.profileImage
    };
    await updateCurrentUser({
      variables: {
        input: userObj,
      },
    });
    setBtnLoading(false);
  };

  return (
    <>
      <Portal portalId="header-wrapper">
        <h2 className="m-0 font-600 mb-12">{MODULES.PROFILE}</h2>
      </Portal>
      {openModal && (
        <ChangePasswordModal
          isModalOpen={openModal}
          setIsModalOpen={setOpenModal}
        />
      )}
      {userData?.profile && (
        <>
          <Form
            form={form}
            className="category-forms"
            layout="vertical"
            initialValues={userData?.profile}
            onFinish={onFinish}
            onValuesChange={(changedValues) => {
              if (changedValues) {
                setFieldsChange(true);
              } else {
                setFieldsChange(false);
              }
            }}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={24} xl={24}>
                <Form.Item name="profileImage" label="Profile photo">
                  <Upload
                    disabled
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                  >
                    {imageUrl ? (
                      <img src={imageUrl} alt="avatar" className="full-width" />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12} xl={8}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    { ...required, message: 'Please Enter First Name' },
                    name,
                  ]}
                >
                  <Input placeholder="Enter First Name" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12} xl={8}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[
                    { ...required, message: 'Please Enter Last Name' },
                    name,
                  ]}
                >
                  <Input placeholder="Enter Last Name" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={24} xl={8}>
                <Form.Item
                  name="email"
                  label="Email Id"
                  rules={[
                    { ...required, message: 'Please Enter Email' },
                    email,
                  ]}
                >
                  <Input disabled placeholder="Enter Email Id" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={24} xl={8}>
                <Form.Item label="Password">
                  <Button
                    type="link"
                    className="change-password-btn p-0"
                    onClick={() => setOpenModal(true)}
                  >
                    Change Password
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Portal portalId="footer-wrapper">
            <Button
              type="primary"
              loading={btnLoading}
              htmlType="submit"
              disabled={!fieldsChange}
              className="full-width submit-btn"
            >
              Save
            </Button>
          </Portal>
        </>
      )}
    </>
  );
}
export default Profile;
