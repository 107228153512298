import { useLazyQuery, useQuery } from '@apollo/client';
import { Breadcrumb, Card } from 'antd';
import { map, trim } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PdfIcon } from '../../../../assets/svg';
import { MODULES, ROUTES } from '../../../../common/constants';
import InfiniteScrollHandler from '../../../../components/InfiniteScrollHandler';
import Portal from '../../../../components/Portal';
import SearchComponent from '../../../../components/SearchComponent';
import { GET_CATEGORY, GET_PRODUCTS } from '../../graphql/Queries';

const CategoryProducts = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const initialProductsFilter = {
    skip: 0,
    limit: 10,
    categoryId: id,
  };

  const initialProductsSort = {
    sortOn: 'createdAt',
    sortBy: 'DESC',
  };

  const [productsFilter, setProductsFilter] = useState(initialProductsFilter);
  const [search, setSearch] = useState('');
  const [scrollFlag, setScrollFlag] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [categoryProductList, setCategoryProductList] = useState([]);

  const { loading: categoriesLoading, data: categoryData } = useQuery(
    GET_CATEGORY,
    {
      variables: {
        where: {
          id,
        },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [fetchProducts, { loading }] = useLazyQuery(GET_PRODUCTS, {
    variables: {
      filters: { ...productsFilter, search, categoryId: id },
      sort: initialProductsSort,
    },
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted: (res) => {
      const data = res?.products?.products;
      if (scrollFlag) {
        const datalist = [...categoryProductList, ...data];
        setCategoryProductList(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setCategoryProductList(datalist);
      }
      setHasMore(!!data?.length);
    },
  });

  useEffect(() => {
    if (id) fetchProducts();
  }, [id]);

  const onSearchChange = async (value) => {
    setSearch(trim(value));
    setProductsFilter({ ...productsFilter, skip: 0, search: value });
    fetchProducts({
      variables: {
        sort: initialProductsSort,
        filters: {
          ...productsFilter,
          skip: 0,
          search: trim(value),
        },
      },
    });
  };

  const handleRefetch = () => {
    fetchProducts({
      variables: {
        sort: initialProductsSort,
        filters: {
          ...productsFilter,
          skip: categoryProductList?.length,
        },
      },
    });
  };

  return (
    <div className="product-module">
      <Portal portalId="header-wrapper">
        <Breadcrumb
          className="d-flex align-center breadcrumb-title"
          items={[
            {
              title: <h2 className="m-0 font-600">{MODULES.CATEGORIES}</h2>,
              onClick: () => navigate(ROUTES.CATEGORIES),
              className: 'pointer',
            },
            {
              title: (
                <h2 className="m-0 font-600">{categoryData?.category?.name}</h2>
              ),
            },
          ]}
        />
      </Portal>
      <SearchComponent
        name="products"
        getData={onSearchChange}
        className="search-component mr-16 full-width mb-20"
      />
      <InfiniteScrollHandler
        scrollFlag={scrollFlag}
        loading={loading || categoriesLoading}
        refetchData={handleRefetch}
        setScrollFlag={setScrollFlag}
        hasMore={hasMore}
        dataLength={categoryProductList?.length}
        skeletonRows={3}
        wrapperClassName="product-list"
      >
        {map(categoryProductList, (record, index) => (
          <Card
            hoverable
            onClick={() => {
              navigate(`${ROUTES.CATEGORIES}/${id}/products/${record?.id}`);
            }}
            className="product-card"
            key={index}
            cover={
              record?.attachmentType === 'IMAGE' ? (
                <img alt="example" src={record?.attachments?.[0]?.url} />
              ) : (
                <div className="pdf-detail-div">
                  <PdfIcon
                    alt="pdf"
                    key={record?.attachments?.[0]?.key}
                    height={40}
                    width={40}
                  />
                </div>
              )
            }
          >
            {record?.name}
          </Card>
        ))}
      </InfiniteScrollHandler>
    </div>
  );
};

export default CategoryProducts;
