import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, InputNumber } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Delete, PdfIcon } from '../../assets/svg';
import { MODULES, ROUTES } from '../../common/constants';
import InfiniteScrollHandler from '../../components/InfiniteScrollHandler';
import Portal from '../../components/Portal';
import ClearCartModal from './ClearCartModal';
import RemoveItemModal from './RemoveItemModal';
import './cart.less';
import { UPDATE_CART_ITEM } from './graphql/Mutations';
import { GET_CART_DETAILS } from './graphql/Queries';

const initialCartFilter = {
  filters: { skip: 0, limit: 10 },
  sort: { sortOn: 'createdAt', sortBy: 'DESC' },
};

const Cart = () => {
  const navigate = useNavigate();
  const [cartData, setCartData] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [removeModal, setRemoveModal] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);
  const [clearCartModal, setClearCartModal] = useState(false);
  const [value, setValue] = useState({});
  const increment = (id) => {
    setValue((prev) => ({
      ...prev,
      [id]: prev[id] + 1,
    }));
  };

  const decrement = (id) => {
    if (value[id] !== 1) {
      setValue((prev) => ({
        ...prev,
        [id]: prev[id] - 1,
      }));
    }
  };

  const [fetchCart, { loading }] = useLazyQuery(GET_CART_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.cart) {
        const data = res?.cart?.items?.items;
        const obj = {};
        if (scrollFlag) {
          const datalist = [...cartData, ...data];
          setCartData(datalist);
          map(datalist, (list) => {
            obj[list.id] = list.quantity;
          });
          setValue(obj);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setCartData(datalist);
          map(datalist, (list) => {
            obj[list.id] = list.quantity;
          });
          setValue(obj);
        }
        setHasMore(!!data?.length);
      } else {
        setCartData([]);
      }
    },
    onError() { },
  });

  const [updateCartItem] = useMutation(UPDATE_CART_ITEM, {
    fetchPolicy: 'network-only',
    onError() { },
    onCompleted() {
      navigate(ROUTES.SELECT_CUSTOMER);
    },
  });
  // const [updateCartItem] = useMutation(UPDATE_CART_ITEM, {
  //   fetchPolicy: 'network-only',
  //   onError() {},
  // });
  useEffect(() => {
    fetchCart({ variables: initialCartFilter });
  }, []);

  const handleFetchMore = () => {
    fetchCart({
      variables: {
        ...initialCartFilter,
        filters: {
          skip: cartData?.length,
          limit: 10,
        },
      },
    });
  };

  const handleRefetch = () => {
    fetchCart({
      variables: {
        ...initialCartFilter,
        filters: {
          skip: 0,
          limit: 10,
        },
      },
    });
  };

  const handleSubmit = () => {
    const inputData = [];
    map(Object.keys(value), (list) => {
      inputData.push({
        cartItemId: list,
        quantity: value?.[list],
      });
    });
    updateCartItem({
      variables: {
        input: inputData,
      },
    });
  };
  return (
    <div className="cart-module">
      {removeModal && (
        <RemoveItemModal
          isModalOpen={removeModal}
          setIsModalOpen={setRemoveModal}
          selectedRecord={itemToRemove}
          setSelectedRecord={setItemToRemove}
          refetch={handleRefetch}
        />
      )}
      {clearCartModal && (
        <ClearCartModal
          isModalOpen={clearCartModal}
          setIsModalOpen={setClearCartModal}
          refetch={handleRefetch}
        />
      )}
      <Portal portalId="header-wrapper">
        <h2 className="m-0 font-600 mb-12">Your {MODULES.CART}</h2>
        <Button
          type="primary"
          className="add-items-btn mb-12"
          onClick={() => navigate(ROUTES.CATEGORIES)}
        >
          ADD MORE ITEMS
        </Button>
      </Portal>
      <InfiniteScrollHandler
        scrollFlag={scrollFlag}
        loading={loading}
        refetchData={handleFetchMore}
        setScrollFlag={setScrollFlag}
        hasMore={hasMore}
        dataLength={cartData?.length}
        skeletonRows={3}
        wrapperClassName="cart-list"
        customDescription="No items added"
      >
        {map(cartData, (item, index) => {
          const attachmentType = item?.product?.attachmentType;
          const attachment = item?.product?.attachments?.[0];
          // setValue(item?.quantity);
          return (
            <Card className="cart-card mb-8" key={index}>
              <div className="cart-content">
                <div className="d-flex align-start">
                  <div className="cart-attachment-div">
                    {attachmentType === 'IMAGE' ? (
                      <img
                        alt="example"
                        src={attachment?.url}
                        height={120}
                        width={120}
                      />
                    ) : (
                      <div className="pdf-detail-div">
                        <PdfIcon
                          alt="pdf"
                          key={attachment?.key}
                          height={100}
                          width={100}
                        />
                      </div>
                    )}
                  </div>
                  <div className="ml-12">
                    <h4 className="m-0">{item?.product?.code}</h4>
                    <p className="m-0 product-name">{item?.product?.name}</p>
                    <div className="mt-6 variations-div">
                      {map(item?.details, (productItem) => (
                        <p className="m-0">
                          {productItem?.name}: {productItem?.value?.name}
                        </p>
                      ))}
                    </div>
                  </div>
                  <Button
                    icon={<Delete height={18} width={18} />}
                    className="delete-btn"
                    onClick={() => {
                      setItemToRemove(item?.id);
                      setRemoveModal(true);
                    }}
                  />
                </div>
                <div className="counter-buttons-div mt-8">
                  <Button
                    onClick={() => decrement(item?.id)}
                    className="minus-button"
                  >
                    <MinusOutlined />
                  </Button>
                  <InputNumber
                    readOnly
                    onChange={setValue}
                    value={value[item?.id]}
                  />
                  <Button
                    onClick={() => increment(item?.id)}
                    className="plus-button"
                  >
                    <PlusOutlined />
                  </Button>
                </div>
              </div>
            </Card>
          );
        })}
      </InfiniteScrollHandler>
      {cartData?.length > 0 && (
        <div className="clear-cart">
          <Button
            onClick={() => {
              setClearCartModal(true);
            }}
          >
            Clear Cart
          </Button>
        </div>
      )}
      {cartData?.length > 0 && (
        <Portal portalId="footer-wrapper">
          <Button
            type="primary"
            htmlType="submit"
            className="full-width submit-btn"
            onClick={() => handleSubmit()}
          >
            Select Customer
          </Button>
        </Portal>
      )}
    </div>
  );
};

export default Cart;
