import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Breadcrumb,
  Button,
  Carousel,
  Col,
  Divider,
  Image,
  InputNumber,
  Radio,
  Row,
  Spin,
} from 'antd';
import { filter, find, map } from 'lodash';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PdfIcon } from '../../../../assets/svg';
import { MODULES, ROUTES } from '../../../../common/constants';
import Portal from '../../../../components/Portal';
import { ADD_ITEM_TO_CART } from '../../graphql/Mutations';
import { GET_CATEGORY_VARIATIONS, GET_PRODUCT } from '../../graphql/Queries';

const ProductDetails = () => {
  const { productId, id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [selectedVariations, setSelectedVariations] = useState([]);
  const increment = () => {
    setValue(value + 1);
  };

  const decrement = () => {
    if (value !== 1) {
      setValue(value - 1);
    }
  };
  const { data, loading } = useQuery(GET_PRODUCT, {
    variables: {
      where: {
        id: productId,
      },
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [addItemToCart] = useMutation(ADD_ITEM_TO_CART, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const {
    data: categoryVariationsData,
    loading: categoryVariationLoading,
  } = useQuery(GET_CATEGORY_VARIATIONS, {
    variables: {
      filters: { bypassPagination: true, categoryId: id },
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const handleRadioChange = (record, text) => {
    if (find(selectedVariations, { key: record?.key })) {
      const filteredData = filter(
        selectedVariations,
        (list) => list?.key !== record?.key,
      );
      setSelectedVariations([
        ...filteredData,
        {
          key: record?.key,
          value: text,
        },
      ]);
    } else {
      setSelectedVariations((prev) => [
        ...prev,
        {
          key: record?.key,
          value: text,
        },
      ]);
    }
  };

  const handleAddToCart = () => {
    addItemToCart({
      variables: {
        input: {
          productId,
          quantity: value,
          variations: selectedVariations,
        },
      },
    });
  };

  if (loading || categoryVariationLoading) {
    return <Spin spinning />;
  }

  return (
    <div className="product-details">
      <Portal portalId="header-wrapper">
        <Breadcrumb
          className="d-flex align-center breadcrumb-title"
          items={[
            {
              title: <h1 className="m-0 font-600">{MODULES.CATEGORIES}</h1>,
              onClick: () => navigate(ROUTES.CATEGORIES),
              className: 'pointer',
            },
            {
              title: (
                <h2 className="m-0 font-600">
                  {data?.product?.category?.name ?? ''}
                </h2>
              ),
              onClick: () =>
                navigate(
                  `${ROUTES.CATEGORIES}/${data?.product?.category?.id}/products`,
                ),
              className: 'pointer',
            },
            {
              title: (
                <h2 className="m-0 font-600">{data?.product?.name ?? ''}</h2>
              ),
            },
          ]}
        />
      </Portal>
      <Carousel className="product-carousel">
        {data?.product?.attachmentType === 'IMAGE' ? (
          map(data?.product?.attachments, (record) => (
            <div className="image-div">
              <Image
                className="mr-8"
                src={record?.url}
                alt="img"
                key={record?.key}
              />
            </div>
          ))
        ) : (
          <div className="image-div">
            <PdfIcon
              alt="pdf"
              key={data?.product?.attachments?.[0]?.key}
              height={60}
              width={60}
              onClick={() =>
                // eslint-disable-next-line no-undef
                window.open(data?.product?.attachments?.[0]?.url, '_blank')
              }
            />
          </div>
        )}
      </Carousel>
      <Divider />
      <div>
        {map(
          categoryVariationsData?.categoryVariations?.categoryVariations,
          (record) => (
            <div className="variation-list">
              <p>{record?.name}</p>
              <Radio.Group optionType="button">
                {map(record?.values, (list) => (
                  <Radio
                    value={list?.key}
                    onChange={(e) =>
                      handleRadioChange(record, e?.target?.value)
                    }
                  >
                    {list?.name}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          ),
        )}
      </div>
      <Row justify="space-between mb-10 mt-20" gutter={10}>
        <Col span={12} className="counter-buttons">
          <Button onClick={decrement} className="minus-button">
            <MinusOutlined />
          </Button>
          <InputNumber readOnly onChange={setValue} value={value} />
          <Button onClick={increment} className="plus-button">
            <PlusOutlined />
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            className="full-width"
            onClick={() => handleAddToCart()}
          >
            ADD TO CART
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default ProductDetails;
