import { Layout, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { CartIcon, Logo } from '../assets/svg';
import { APP_VERSION, BREAKPOINTS, ROUTES } from '../common/constants';
import './App.css';
import AppHeader from './components/header/AppHeader';
import MobileHeader from './components/header/MobileHeader';

const { Content, Footer } = Layout;
const App = () => {
  const navigate = useNavigate();
  const [isDesktop, setDesktop] = useState(
    // eslint-disable-next-line no-undef
    window.innerWidth > BREAKPOINTS.tablet,
  );
  // eslint-disable-next-line no-unused-vars
  const location = useLocation();
  const [isActive, setActive] = useState(false);

  const handleOverlay = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      if (window.innerWidth > BREAKPOINTS.tablet) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <Layout className="app-layout full-height">
      {!isDesktop && (
        <span
          className={isActive ? 'active overlay-responsive' : 'overlay-disable'}
          onClick={handleOverlay}
        />
      )}
      <Layout className="site-layout full-height">
        <AppHeader>
          <div className="d-flex align-center justify-between">
            <Link id="logo" to={ROUTES?.MAIN}>
              <Logo width={100} />
            </Link>
            <div className="d-flex">
              <Tooltip title="Cart">
                <CartIcon
                  className="mr-16 pointer"
                  width={25}
                  height={25}
                  onClick={() => navigate(ROUTES.CART)}
                />
              </Tooltip>
              <MobileHeader />
            </div>
          </div>
        </AppHeader>
        <Content className="wrapper">
          <div id="header-wrapper" className="header-wrapper" />
          <Outlet />
        </Content>
        {/* {includes(location.pathname.split('/'), 'cart') && ( */}
        <Footer className="footer-content">
          <div id="footer-wrapper" className="footer-wrapper" />
          <div className="app-version">v{APP_VERSION}</div>
        </Footer>
        {/* )} */}
      </Layout>
    </Layout>
  );
};

export default App;
