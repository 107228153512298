import { useLazyQuery } from '@apollo/client';
import { Tag } from 'antd';
import dayjs from 'dayjs';
import { capitalize, map, trim } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MODULES,
  ROUTES,
  STATUS_COLORS,
  defaultDateFormat,
} from '../../common/constants';
import CommonCard from '../../components/CommonCard';
import InfiniteScrollHandler from '../../components/InfiniteScrollHandler';
import Portal from '../../components/Portal';
import SearchComponent from '../../components/SearchComponent';
import { ORDERS } from './graphql/Queries';
import './orders.less';

const Orders = () => {
  const navigate = useNavigate();

  const initialCustomerFilter = {
    skip: 0,
    limit: 10,
  };

  const initialCustomerSort = {
    sortOn: 'createdAt',
    sortBy: 'DESC',
  };

  const [customerFilter, setCustomerFilter] = useState(initialCustomerFilter);
  const [search, setSearch] = useState('');
  const [scrollFlag, setScrollFlag] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [customerList, setCustomerList] = useState([]);
  const [orders, { loading }] = useLazyQuery(ORDERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.orders?.orders;
      if (scrollFlag) {
        const datalist = [...customerList, ...data];
        setCustomerList(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setCustomerList(datalist);
      }
      setHasMore(!!data?.length);
    },
    onError() {},
  });

  useEffect(() => {
    orders({
      variables: {
        filters: { ...customerFilter, search },
        sort: initialCustomerSort,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchChange = async (value) => {
    setSearch(trim(value));
    setCustomerFilter({ ...customerFilter, skip: 0, search: value });
    orders({
      variables: {
        sort: initialCustomerSort,
        filters: {
          ...customerFilter,
          skip: 0,
          search: trim(value),
        },
      },
    });
  };

  const handleRefetch = () => {
    orders({
      variables: {
        sort: initialCustomerSort,
        filters: {
          ...customerFilter,
          skip: customerList?.length,
          limit: 10,
        },
      },
    });
  };

  return (
    <div className="customer-module">
      <Portal portalId="header-wrapper">
        <div className="full-width d-flex align-center justify-between mb-12">
          <h2 className="m-0 font-600">{MODULES.ORDERS}</h2>
        </div>
      </Portal>
      <SearchComponent
        id="search-container-id"
        name="order"
        getData={onSearchChange}
        className="mb-20 search-component"
      />
      <InfiniteScrollHandler
        scrollFlag={scrollFlag}
        loading={loading}
        refetchData={handleRefetch}
        setScrollFlag={setScrollFlag}
        hasMore={hasMore}
        dataLength={customerList?.length}
        skeletonRows={3}
        // wrapperClassName="category-list"
      >
        {map(customerList, (customer, index) => (
          <CommonCard
            key={customer?.id}
            onClick={() => navigate(`${ROUTES.ORDERS}/${customer?.id}`)}
            className="order-list mb-10"
          >
            <div className="common-card d-flex space-between">
              <div className="mr-5 title">{index + 1}.</div>
              <div>
                <div className="card-content text-secondary">
                  <div className="mb-5">
                    <span className="title mr-5">Order Number:</span>
                    <span className="text-break description">
                      {customer?.orderNo}
                    </span>
                  </div>
                  <div className="mb-5">
                    <span className="title mr-5">Status:</span>
                    <span className="text-break description">
                      <Tag className={STATUS_COLORS[customer?.status]}>
                        {capitalize(customer?.status)}
                      </Tag>
                    </span>
                  </div>
                  <div className="mb-5">
                    <span className="title mr-5">Total Quantity:</span>
                    <span className="text-break description">
                      {customer?.totalQuantity || '-'}
                    </span>
                  </div>
                  <div className="mb-5">
                    <span className="title mr-5">Placed On:</span>
                    <span className="text-break description">
                      {dayjs(customer?.createdAt).format(defaultDateFormat) ||
                        '-'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </CommonCard>
        ))}
      </InfiniteScrollHandler>
    </div>
  );
};
export default Orders;
