import { gql } from '@apollo/client';

export const LOGOUT_USER = gql`
  mutation logout {
    logout {
      message
    }
  }
`;

export const PROFILE = gql`
  query Profile {
    profile {
      email
      firstName
      id
      isActive
      lastName
      profilePicture
      role
    }
  }
`;
