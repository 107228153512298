import { gql } from '@apollo/client';

export const ORDERS = gql`
  query Orders($filters: OrdersFilterInput, $sort: OrdersSortInput) {
    orders(filters: $filters, sort: $sort) {
      count
      orders {
        carrierProvider
        createdAt
        id
        orderNo
        status
        totalQuantity
        shipmentId
        orderBy {
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const ORDER = gql`
  query Order($where: UniqueWhereInput!) {
    order(where: $where) {
      carrierProvider
      createdAt
      id
      invoicePdfUrl {
        key
        url
      }
      orderBy {
        id
      }
      customer {
        email
        name
        id
      }
      items {
        details {
          key
          name
          value {
            key
            name
          }
        }
        id
        pricePerUnit
        quantity
        product {
          attachmentType
          attachments {
            key
            url
          }
          category {
            createdAt
            id
            image {
              key
              url
            }
            name
            slug
            updatedAt
          }
          code
          createdAt
          id
          name
          slug
          status
        }
      }
      orderNo
      shipmentId
      status
      totalQuantity
    }
  }
`;
