import { gql } from '@apollo/client';

export const CUSTOMER = gql`
  query Customers($filters: CustomersFilterInput, $sort: CustomersSortInput) {
    customers(filters: $filters, sort: $sort) {
      count
      customers {
        city
        country
        countryCode
        createdAt
        email
        name
        orderCount
        id
        proofType
        proofNo
        customCode
        phoneNo
        state
        zipCode
      }
    }
  }
`;
export const GET_CUSTOMER = gql`
  query Customer($where: UniqueWhereInput!) {
    customer(where: $where) {
      city
      country
      countryCode
      createdAt
      email
      name
      id
      customCode
      phoneNo
      proofNo
      proofType
      state
      zipCode
    }
  }
`;
