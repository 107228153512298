import { useQuery } from '@apollo/client';
import { Breadcrumb, Descriptions, Radio, Spin } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  MODULES,
  PROOF_TYPE,
  ROUTES,
  defaultDateFormat,
} from '../../common/constants';
import Portal from '../../components/Portal';
import './customer.less';
import { GET_CUSTOMER } from './graphql/Queries';

const CustomerDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: 'network-only',
    onError() { },
  });

  const items = [
    {
      key: '1',
      label: 'Name',
      children: data?.customer?.name,
    },
    {
      key: '2',
      label: 'Custom Code',
      children: data?.customer?.customCode,
    },
    {
      key: '3',
      label: 'Email',
      children: data?.customer?.email,
    },
    {
      key: '4',
      label: 'Contact Details',
      children: data?.customer?.phoneNo,
    },
    {
      key: '5',
      label: 'Added On',
      children: dayjs(data?.customer?.createdAt).format(defaultDateFormat),
    },
    {
      key: '6',
      label: 'City',
      children: data?.customer?.city,
    },
    {
      key: '7',
      label: 'State',
      children: data?.customer?.state,
    },
    {
      key: '8',
      label: 'Country',
      children: data?.customer?.country,
    },
    {
      key: '9',
      label: 'Zipcode',
      children: data?.customer?.zipCode,
    },
    {
      key: '10',
      label: 'Proof Number',
      children: data?.customer?.proofNo,
    },
    {
      key: '11',
      label: 'Proof Type',
      className: 'other-type',
      children: (
        <Radio.Group value={data?.customer?.proofType}>
          <Radio value={PROOF_TYPE.PAN}>{PROOF_TYPE.PAN}</Radio>
          <Radio value={PROOF_TYPE.GST}>{PROOF_TYPE.GST}</Radio>
          <Radio value={PROOF_TYPE.AADHAAR}>{PROOF_TYPE.AADHAAR}</Radio>
        </Radio.Group>
      ),
    },
  ];

  return (
    <div className="member-details">
      <Portal portalId="header-wrapper">
        <Breadcrumb
          className="d-flex align-center breadcrumb-title"
          items={[
            {
              title: <h1 className="m-0 font-600">{MODULES.CUSTOMER}</h1>,
              onClick: () => navigate(ROUTES.CUSTOMER),
              className: 'pointer',
            },
            {
              title: (
                <h2 className="m-0 font-600">
                  {data?.customer?.firstName} {data?.customer?.lastName}
                </h2>
              ),
            },
          ]}
        />
      </Portal>

      <Spin spinning={loading}>
        <Descriptions layout="vertical" items={items} colon={false} />
      </Spin>
    </div>
  );
};

export default CustomerDetails;
