import { gql } from '@apollo/client';

export const GET_CART_DETAILS = gql`
  query Cart($filters: CartFilterInput, $sort: CartSortInput) {
    cart(filters: $filters, sort: $sort) {
      id
      customer {
        email
        name
        id
        orderCount
      }
      items {
        count
        items {
          details {
            name
            key
            value {
              key
              name
            }
          }
          id
          product {
            id
            name
            attachmentType
            attachments {
              key
              url
            }
            code
          }
          quantity
        }
      }
      creator {
        firstName
        id
        email
        lastName
      }
    }
  }
`;

export const GET_CART_ITEMS_COUNT = gql`
  query Cart($filters: CartFilterInput, $sort: CartSortInput) {
    cart(filters: $filters, sort: $sort) {
      id
      items {
        count
        items {
          id
        }
      }
    }
  }
`;
