import { useLazyQuery } from '@apollo/client';
import { Breadcrumb, Card, Divider } from 'antd';
import dayjs from 'dayjs';
import { capitalize, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PdfIcon, Verify } from '../../assets/svg';
import {
  MODULES,
  ROUTES,
  STATUS,
  defaultDateFormat,
} from '../../common/constants';
import CommonCard from '../../components/CommonCard';
import LoaderComponent from '../../components/LoaderComponent';
import Portal from '../../components/Portal';
import '../cart/cart.less';
import '../customer/customer.less';
import { ORDER } from './graphql/Queries';
import './orders.less';

const OrderDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [cartData, setCartData] = useState([]);
  const [mainCartData, setMainCartData] = useState();
  const [scrollFlag, setScrollFlag] = useState(false);

  const [fetchCart, { loading }] = useLazyQuery(ORDER, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.order) {
        setMainCartData(res?.order);
        const data = res?.order?.items;
        if (scrollFlag) {
          const datalist = [...cartData, ...data];
          setCartData(datalist);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setCartData(datalist);
        }
      } else {
        setCartData([]);
      }
    },
    onError() { },
  });

  useEffect(() => {
    fetchCart({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  const getMessage = (status, orderNo) => {
    switch (capitalize(status)) {
      case STATUS.PLACED:
        return `Your order number #${orderNo} is placed.`;
      case STATUS.PROCESSING:
        return `Your order number #${orderNo} is currently in progress.`;
      case STATUS.COMPLETED:
        return `Your order number #${orderNo} has been completed successfully.`;
      case STATUS.INVOICED:
        return `Your order number #${orderNo} has invoiced.`;
      case STATUS.DISPATCHED:
        return `Your order number #${orderNo} has been dispatched.`;
      case STATUS.SHIPPED:
        return `Your order number #${orderNo} has been shipped.`;

      default:
        break;
    }
  };

  if (loading) {
    return <LoaderComponent />;
  }
  return (
    <div className="cart-module">
      <Portal portalId="header-wrapper">
        <Breadcrumb
          className="d-flex align-center breadcrumb-title"
          items={[
            {
              title: <h1 className="m-0 font-600">{MODULES.ORDERS}</h1>,
              onClick: () => navigate(ROUTES.ORDERS),
              className: 'pointer',
            },
            {
              title: (
                <h2 className="m-0 font-600">{mainCartData?.orderNo ?? ''}</h2>
              ),
            },
          ]}
        />
      </Portal>
      <Card className="order-success-card mb-8">
        <div className="order-verify-div">
          {state?.justPlaced ? (
            <div className="d-flex align-start">
              <div className="cart-attachment-div">
                <Verify height={80} width={80} />
              </div>
              <div className="ml-12">
                <h4 className="m-0 heading">Order Placed Successfully!</h4>
                <p className="m-0 message">
                  Thank you for your order, here is your order number #
                  {mainCartData?.orderNo}.
                </p>
              </div>
            </div>
          ) : (
            <div className="d-flex align-start">
              <div className="ml-12">
                <h4 className="m-0 heading">
                  Status: {capitalize(mainCartData?.status)}
                </h4>
                <p className="m-0 message">
                  {getMessage(mainCartData?.status, mainCartData?.orderNo)}
                </p>
              </div>
            </div>
          )}
        </div>
      </Card>
      <div className="placed-on">
        Order Placed On:{' '}
        {dayjs(mainCartData?.createdAt).format(defaultDateFormat)}
      </div>
      <Divider dashed />
      <div className="heading">Customer Details</div>
      <CommonCard
        key={mainCartData?.customer?.id}
        className="customer-list customer-data mb-10"
      >
        <div className="common-card d-flex space-between">
          <div>
            <div className="card-content text-secondary">
              <div className="mb-5">
                <span className="title mr-5">Name:</span>
                <span className="text-break description">
                  {mainCartData?.customer?.name}
                </span>
              </div>
              <div className="mb-5">
                <span className="title mr-5">Email:</span>
                <span className="text-break description">
                  {mainCartData?.customer?.email || '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </CommonCard>
      <div className="heading">Order Details</div>
      {map(cartData, (item, index) => {
        const attachmentType = item?.product?.attachmentType;
        const attachment = item?.product?.attachments?.[0];
        return (
          <Card className="cart-card mb-8" key={index}>
            <div className="cart-content">
              <div className="d-flex align-start">
                <div className="cart-attachment-div">
                  {attachmentType === 'IMAGE' ? (
                    <img
                      alt="example"
                      src={attachment?.url}
                      height={120}
                      width={120}
                    />
                  ) : (
                    <div className="pdf-detail-div">
                      <PdfIcon
                        alt="pdf"
                        key={attachment?.key}
                        height={100}
                        width={100}
                      />
                    </div>
                  )}
                </div>
                <div className="ml-12">
                  <h4 className="m-0">{item?.product?.code}</h4>
                  <p className="m-0 product-name">{item?.product?.name}</p>
                  <div className="mt-6 variations-div">
                    <p className="m-0">Quantity: {item?.quantity}</p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default OrderDetail;
