import { useMutation } from '@apollo/client';
import { Button, Form, Input, Spin } from 'antd';
import React from 'react';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { FORGOT_PASSWORD } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const ForgetPassword = () => {
  const { navigate } = useRouter();
  const [forgetPasswordMutating, { loading }] = useMutation(FORGOT_PASSWORD, {
    onError() {}, // Always write this method for error handling in all mutation.
  });

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim()?.toLowerCase(),
      };
      await forgetPasswordMutating({
        variables: { input: { ...formValues } },
      });
      navigate(ROUTES.LOGIN);
    } catch (error) {
      return error;
    }
  };
  return (
    <div className="auth-bg">
      <div className="login-wrap">
        <Spin spinning={loading} className="full-width">
          <h1 className="m-0">FORGOT PASSWORD</h1>
          <p>We’ll send a recovery link via registered email address</p>
          <Form
            layout="vertical"
            onFinish={onFinish}
            className="category-forms"
          >
            <Form.Item
              name="email"
              label="Email Address"
              rules={[{ required, message: 'Please enter email!' }, email]}
            >
              <Input placeholder="Enter your email address" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                loading={loading}
                className="full-width mb-0 submit-btn font-600"
                htmlType="submit"
              >
                SEND LINK
              </Button>
            </Form.Item>
            <Form.Item className="text-center mb-0">
              <Button
                type="link"
                className="full-width mb-0 submit-btn font-600"
                onClick={() => {
                  navigate(ROUTES?.LOGIN);
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default ForgetPassword;
