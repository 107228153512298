import { gql } from '@apollo/client';

export const CATEGORIES = gql`
  query Categories(
    $filters: CategoriesFilterInput
    $sort: CategoriesSortInput
  ) {
    categories(filters: $filters, sort: $sort) {
      categories {
        createdAt
        id
        image {
          key
          url
        }
        name
        slug
        updatedAt
      }
      count
    }
  }
`;

export const GET_CATEGORY_IMAGE_UPLOAD_URL = gql`
  query getCategoryImageUploadSignedUrl($input: GetUploadSignedUrlInput!) {
    getCategoryImageUploadSignedUrl(input: $input) {
      key
      signedUrl
    }
  }
`;

export const GET_CATEGORY_VARIATIONS = gql`
  query CategoryVariations(
    $filters: CategoryVariationsFilterInput!
    $sort: CategoryVariationsSortInput
  ) {
    categoryVariations(filters: $filters, sort: $sort) {
      categoryVariations {
        id
        name
        key
        createdAt
        values {
          key
          name
        }
      }
      count
    }
  }
`;

export const GET_PRODUCTS = gql`
  query Products($filters: ProductsFilterInput, $sort: ProductsSortInput) {
    products(filters: $filters, sort: $sort) {
      count
      products {
        id
        code
        name
        attachmentType
        attachments {
          key
          url
        }
        createdAt
        category {
          name
          id
        }
        status
      }
    }
  }
`;

export const GET_PRODUCT_ATTACHMENT_UPLOAD_URL = gql`
  query GetProductAttachmentUploadSignedUrl($input: GetUploadSignedUrlInput!) {
    getProductAttachmentUploadSignedUrl(input: $input) {
      key
      signedUrl
    }
  }
`;

export const GET_CATEGORY = gql`
  query Category($where: UniqueWhereInput!) {
    category(where: $where) {
      name
    }
  }
`;

export const GET_PRODUCT = gql`
  query Product($where: UniqueWhereInput!) {
    product(where: $where) {
      id
      name
      category {
        id
        name
      }
      attachmentType
      attachments {
        key
        url
      }
      status
      code
      createdAt
    }
  }
`;

export const INVENTORIES = gql`
  query Inventories(
    $filters: InventoriesFilterInput!
    $sort: InventoriesSortInput
  ) {
    inventories(filters: $filters, sort: $sort) {
      count
      inventories {
        addedOn
        id
        product {
          attachmentType
          attachments {
            key
            url
          }
          category {
            createdAt
            id
            image {
              key
              url
            }
            name
            slug
            updatedAt
          }
          code
          createdAt
          id
          name
          slug
          status
        }
        productVariation {
          code
          details {
            key
            name
            value {
              key
              name
            }
          }
          id
          quantity
        }
        quantity
        type
      }
    }
  }
`;

export const GET_PRODUCT_VARIATIONS = gql`
  query ProductVariations(
    $filters: ProductVariationsFilterInput!
    $sort: ProductVariationsSortInput
  ) {
    productVariations(filters: $filters, sort: $sort) {
      count
      productVariations {
        code
        id
        quantity
        createdAt
        details {
          name
          key
          value {
            key
            name
          }
        }
      }
    }
  }
`;
