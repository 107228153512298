import { useMutation } from '@apollo/client';
import { Button, Col, Modal, Row } from 'antd';
import { upperCase } from 'lodash';
import React from 'react';
import { CLEAR_CART } from './graphql/Mutations';

function ClearCartModal({ isModalOpen, setIsModalOpen, refetch }) {
  const [clearCart, { loading }] = useMutation(CLEAR_CART, {
    onCompleted() {
      setIsModalOpen(false);
      refetch();
    },
    onError() {},
  });

  const handleDelete = () => {
    clearCart();
  };

  return (
    <div id="delete-categories-modal">
      <Modal
        centered
        closable={false}
        title={<h3 className="m-0 mb-10">{upperCase('Clear Cart')}</h3>}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        open={isModalOpen}
        width={576}
      >
        <p className="mb-16 mt-10">
          Clearing you cart will remove all the items in it. Are you sure you
          want to clear your cart?
        </p>
        <Row gutter={10}>
          <Col span={12}>
            <Button
              disabled={loading}
              onClick={() => {
                setIsModalOpen(false);
              }}
              className="full-width font-600 submit-btn"
            >
              Discard
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              className="full-width font-600 submit-btn"
              loading={loading}
              onClick={() => handleDelete()}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default ClearCartModal;
