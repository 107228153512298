import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Space, theme } from 'antd';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LogoutIcon, ProfileIcon } from '../../../assets/svg';
import { MODULES, ROUTES } from '../../../common/constants';
import { getItem } from '../../../common/utils';

const { useToken } = theme;
const MobileHeader = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [visible, setVisible] = useState(false);
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: 'none',
  };

  const onMenuSelect = (e) => {
    navigate(e?.key);
    setVisible(false);
  };

  const menuItems = [
    getItem(MODULES?.CATEGORY, ROUTES?.CATEGORIES),
    {
      type: 'divider',
    },
    getItem(MODULES?.CUSTOMER, ROUTES?.CUSTOMER),
    {
      type: 'divider',
    },
    getItem(MODULES?.ORDERS, ROUTES?.ORDERS),
  ];

  return (
    <div>
      <div className="d-flex justify-between align-center full-width">
        <div className="d-flex align-center dropdown-div">
          <Dropdown
            menu={{
              items: menuItems,
              selectedKeys: [`${pathname?.split('/')?.[1]}`],
              defaultSelectedKeys: [ROUTES?.CATEGORIES],
              onClick: (e) => onMenuSelect(e),
              onSelect: (e) => onMenuSelect(e),
              selectable: true,
            }}
            trigger={['click']}
            placement="bottom"
            overlayClassName="mobile-header"
            getPopupContainer={() =>
              // eslint-disable-next-line no-undef
              document.querySelector('#nav-bar-dropdown')
            }
            onOpenChange={(value) => setVisible(value)}
            open={visible}
            dropdownRender={(menu) => (
              <div style={contentStyle}>
                {React.cloneElement(menu, {
                  style: menuStyle,
                })}
                <div className="extra-header-btns">
                  <Button
                    icon={<ProfileIcon />}
                    className="d-flex align-center justify-center"
                    onClick={() => {
                      navigate(ROUTES.PROFILE);
                      setVisible(false);
                    }}
                  >
                    Profile
                  </Button>
                </div>
                <Divider className="m-0" />
                <Space className="logout-header-btn">
                  <Button
                    icon={<LogoutIcon />}
                    color="danger"
                    className="d-flex align-center justify-center full-width"
                    onClick={() => {
                      navigate(ROUTES.LOGOUT);
                    }}
                  >
                    Logout
                  </Button>
                </Space>
              </div>
            )}
          >
            {visible ? <CloseOutlined /> : <MenuOutlined />}
          </Dropdown>
        </div>
      </div>
      <div id="nav-bar-dropdown" />
    </div>
  );
};
export default MobileHeader;
