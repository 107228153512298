import { useLazyQuery } from '@apollo/client';
import { Card, Col, Row } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MODULES, ROUTES } from '../../common/constants';
import InfiniteScrollHandler from '../../components/InfiniteScrollHandler';
import Portal from '../../components/Portal';
import SearchComponent from '../../components/SearchComponent';
import './categories.less';
import { CATEGORIES } from './graphql/Queries';

const Categories = () => {
  const initialUserFilter = {
    filters: { skip: 0, limit: 10 },
    sort: { sortOn: 'createdAt', sortBy: 'DESC' },
  };

  const navigate = useNavigate();
  const [userFilter, setUserFilter] = useState(initialUserFilter);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [categoriesList, setCategoriesList] = useState([]);
  const [listCompaniesDetails, { loading }] = useLazyQuery(CATEGORIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.categories?.categories;
      if (scrollFlag) {
        const datalist = [...categoriesList, ...data];
        setCategoriesList(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setCategoriesList(datalist);
      }
      setHasMore(!!data?.length);
    },
    onError() {},
  });

  useEffect(() => {
    listCompaniesDetails({ variables: userFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchChange = async (value) => {
    setUserFilter({
      ...userFilter,
      filters: {
        skip: 0,
        limit: 10,
        search: value,
      },
    });
    listCompaniesDetails({
      variables: {
        ...userFilter,
        filters: {
          skip: 0,
          limit: 10,
          search: value,
        },
      },
    });
  };

  const handleRefetch = () => {
    listCompaniesDetails({
      variables: {
        ...userFilter,
        filters: {
          skip: categoriesList?.length,
          limit: 10,
        },
      },
    });
  };
  return (
    <div className="categories-module">
      <Portal portalId="header-wrapper">
        <h2 className="m-0 font-600 mb-12">{MODULES.CATEGORIES}</h2>
      </Portal>
      <Row className="mb-24" justify="space-between">
        <Col span={24}>
          <SearchComponent
            id="search-container-id"
            name="categories"
            getData={onSearchChange}
            className="category-search search-component"
          />
        </Col>
      </Row>
      <InfiniteScrollHandler
        scrollFlag={scrollFlag}
        loading={loading}
        refetchData={handleRefetch}
        setScrollFlag={setScrollFlag}
        hasMore={hasMore}
        dataLength={categoriesList?.length}
        skeletonRows={3}
        wrapperClassName="category-list"
      >
        {map(categoriesList, (project, index) => (
          <Card
            hoverable
            onClick={() => {
              navigate(`${ROUTES.CATEGORIES}/${project?.id}/products`);
            }}
            className="category-card"
            key={index}
            cover={<img alt="example" src={project?.image?.url} />}
          >
            {project?.name}
          </Card>
        ))}
      </InfiniteScrollHandler>
    </div>
  );
};
export default Categories;
