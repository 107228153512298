import { useMutation } from '@apollo/client';
import { Button, Form, Input, Spin } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import './auth.less';
import { LOGIN } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const Login = () => {
  const [form] = Form.useForm();
  const { navigate } = useRouter();
  const { initializeAuth } = useContext(AppContext);
  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {}, // Always write this method for error handling in all mutation.
  });

  function successCallback(accessToken, userData, refreshToken) {
    initializeAuth(accessToken, userData, refreshToken);
    navigate('/');
  }

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
        password: values?.password?.trim(),
      };
      const response = await loginMutate({
        variables: { input: { ...formValues } },
      });
      if (response?.data) {
        const accessToken = response?.data?.login?.accessToken;
        const userData = response?.data?.login?.user;
        const tokenRefresh = response?.data?.login?.refreshToken;

        if (successCallback) {
          successCallback(accessToken, userData, tokenRefresh);
        }
      } else {
        form?.setFieldsValue(values);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <div className="auth-bg">
      <div className="login-wrap">
        <Spin spinning={loginLoading} wrapperClassName="full-width">
          <h1 className="m-0">LOGIN</h1>
          <p>Sign in to your admin account</p>
          <Form
            name="Login"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            form={form}
            className="category-forms"
          >
            <Form.Item
              name="email"
              label="Email Address"
              rules={[{ required, message: 'Please enter email!' }, email]}
            >
              <Input placeholder="Enter your email address" />
            </Form.Item>

            <Form.Item
              name="password"
              className="mb-8"
              label="Password"
              rules={[{ required, message: 'Please enter password!' }]}
            >
              <Input.Password
                placeholder="Enter password"
                className="password-input"
              />
            </Form.Item>
            <Form.Item className="text-right mb-8 forgot-password-link">
              <Link to={ROUTES?.FORGET_PASSWORD}>Forgot password ?</Link>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                className="full-width mb-0 submit-btn font-600"
                htmlType="submit"
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default Login;
