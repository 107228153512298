import app from '../../package.json';

/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  RESET: '/reset-password',
  SET_PASSWORD: '/set-password',
  VERIFY: '/verify',
  AUTHENTICATION: '/authentication',
  MOVIES: '/movies',
  MOVIE_DETAIL: '/movies/:id',
  ADD_MOVIES: '/movies/add',
  EDIT_MOVIES: '/movies/edit/:id',
  TABLE: '/tables',
  SAMPLE: '/sample',
  PROFILE: '/profile',
  CATEGORIES: '/categories',
  MEMBERS: '/members',
  CUSTOMER: '/customer',
  ORDER_AND_BILLING: '/order-and-billing',
  CART: '/cart',
  SELECT_CUSTOMER: '/cart/select-customer',
  ORDER_CONFIRMATION: '/cart/order-confirmation',
  ORDERS: '/orders',
  ORDER_DETAIL: '/orders/:id',
};

/*  Modules */
export const MODULES = {
  CATEGORIES: 'Categories',
  MEMBERS: 'Members',
  CUSTOMER: 'Customers',
  ORDER_AND_BILLING: 'Order & Billing',
  PROFILE: 'Profile',
  PRODUCTS: 'Products',
  CATEGORY: 'Category',
  ORDERS: 'Orders',
  CHANGE_PASSWORD: 'Change Password',
  CART: 'Cart',
  SELECT_CUSTOMER: 'Customer List',
  ORDER_CONFIRMATION: 'Order Confirmation',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin',
};

export const ROLE_KEYS = {
  ADMIN: 'ADMIN',
  STAFF: 'STAFF',
  SUPER_ADMIN: 'SUPER_ADMIN',
};

export const PROOF_TYPE = {
  PAN: 'PAN',
  GST: 'GST',
  AADHAAR: 'AADHAAR',
};
/* Date and time */
export const defaultDateFormat = 'D MMM, YYYY';
export const backendMutationDateFormat = 'YYYY-MM-DD';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
  PAN: /[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}/,
  GST: /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1}$/,
  AADHAAR: /^\d{4}\s\d{4}\s\d{4}$/,
  NEW_PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=\\!~`*()_-])[a-zA-Z0-9@#$%^&+=\\!~`*()_-]{8,}$/,
};

export const LIMIT = 10;
export const SCROLL_PERCENT = 0.85;
export const NUMBER_OF_SKELETON_CARDS = 10;

export const MOVIES_SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'Updated At', value: 'updatedAt' },
  { name: 'Release Date', value: 'releaseDate' },
  { name: 'Popularity', value: 'popularity' },
  { name: 'Rate', value: 'voteAverage' },
];
export const ORDER = [
  { name: 'Ascending', value: 'ASC' },
  { name: 'Descending', value: 'DESC' },
];

export const CREDIT_TYPE = [
  { name: 'Cast', value: 'CAST' },
  { name: 'Crew', value: 'CREW' },
];

export const GENDER = [
  { name: 'Female', value: 'FEMALE' },
  { name: 'Male', value: 'MALE' },
  { name: 'Other', value: 'OTHER' },
];
export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const MOVIE_STATUS = {
  PENDING: 'Pending',
  RELEASED: 'Released',
};

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};

export const STATUS_COLORS = {
  DRAFT: 'bg-draft',
  PUBLISHED: 'bg-published',
  UNPUBLISHED: 'bg-unpublished',
  PLACED: 'bg-placed',
  PROCESSING: 'bg-processing',
  INVOICED: 'bg-invoiced',
  DISPATCHED: 'bg-dispatched',
  SHIPPED: 'bg-shipped',
  COMPLETED: 'bg-completed',
};

export const STATUS = {
  PUBLISHED: 'Published',
  UNPUBLISHED: 'Unpublished',
  DRAFT: 'Draft',
  PLACED: 'Placed',
  PROCESSING: 'Processing',
  INVOICED: 'Invoiced',
  DISPATCHED: 'Dispatched',
  SHIPPED: 'Shipped',
  COMPLETED: 'Completed',
};

export const APP_VERSION = app?.version;
